<template>
  <div>

    <v-page-title title="Welcome">
      <v-btn
        to="#support"
        elevation="0"
        color="blue-grey darken-4"
        small
        dark>
        Support Ticket
      </v-btn>
    </v-page-title>

    <v-card max-width="1200" class="mx-auto my-5" elevation="0" color="transparent">
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-zen line-one="Achieve" line-two="Wordpress" line-three="Zen"></v-zen>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-card elevation="0" outlined style="height: 100%;" class="pa-2">
                  <v-card-text style="height: 100%;">
                    <h3 class="text-h5 mb-0 mt-5 font-weight-bold">Your Hounddog Dashboard</h3>
                    <h4 class="text-h6 mb-2 mt-5 font-weight-bold black--text">An all-in-one command center for maintaining peak website performance and security.</h4>
                    
                    <p> The Hounddog app provides you with real-time insights, easy-to-read reports, and actionable recommendations to safeguard your digital assets. Easily manage your sites, monitor their health, and track performance metrics in one streamlined interface. With Hounddog’s robust scanning capabilities, you’ll be alerted to potential vulnerabilities and optimization opportunities, ensuring your site remains secure, fast, and reliable. Whether you’re looking to enhance security protocols, boost site speed, or keep your plugins and themes up-to-date, your Hounddog dashboard is here to guide you every step of the way. Take control of your website’s well-being and enjoy peace of mind knowing Hounddog has your back.</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-account-info></v-account-info>
                <v-user-info class="mt-5"></v-user-info>
              </v-col>
            </v-row>
            <v-row v-show="false">
              <v-col>
                <v-wp-security-news></v-wp-security-news>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Home.vue',
    data() {
      return {
      }
    },
    computed: {

    }
  }
</script>

<style lang="scss">
</style>